<template>
    <div class="screenHeight">
        <section class="settings">
            <router-view></router-view>
            <mobile-navigation></mobile-navigation>
        </section>
    </div>
</template>

<script>
import MobileNavigation from '@/components/MobileNavigation';

export default {
    components: {
        MobileNavigation,
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.screenHeight {
    overflow-y: auto;

    .settings {
        height: 90vh;
        width: 100%;
        z-index: 100;
        background-color: white;

        @media screen and (min-width: 768px) {
            height: 100vh;
            z-index: 100;
            background-color: white;
        }
    }
}
</style>
